<template>
  <div class="box">
    <img class="img" src="../../assets/shouye2_banner333.png" alt="" />
    <div class="mask"></div>
    <div class="content">
      <img src="../../assets/denglu_imgs@3x.png" class="content_img" alt="" />
      <div class="rigth">
        <img class="logo" src="../../assets/logo.png" alt="" />
        <div class="tab">
          <router-link to="/wxlogin" class="item" @click="introduce"
            >微信登录</router-link
          >
          <router-link to="/login" class="item">密码登录</router-link>
          <div class="item active">手机登录</div>
        </div>
        <div class="phone">
          <img src="../../assets/shouji_icon.png" alt="" />
          <input v-model="form.phone" type="text" placeholder="请输入手机号" />
        </div>
        <div class="password">
          <img src="../../assets/mima_icon.png" alt="" />
          <input v-model="form.verify" type="text" placeholder="请输入验证码" />
          <div class="verification">
            <span v-if="time < 0" @click="getCode">发送验证码</span>
            <span v-if="time >= 0">{{ time }} s</span>
          </div>
        </div>
        <div class="btn" @click="register">登录</div>
        <div class="tac">
          还没账号？<span
            @click="goregister"
            style="cursor: pointer; color: #1276fb"
            >立即注册</span
          >
          <span @click="goforgetthepassword" style="cursor: pointer"
            >&nbsp;&nbsp;&nbsp;忘记密码</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
        phone: "",
        verify: "",
      },
      time: -1,
      code: "",
    };
  },
  computed: {
    ...mapState({
      wx: (state) => state.wx,
    }),
  },
  methods: {
    ...mapActions([
      "getBound",
      "getAlibabaMessage_x",
      "getbinding_x",
      "getverificationCodeLogin_x",
      "getsmsVerificationr",
    ]),
    // 忘记密码
    goforgetthepassword() {
      this.$router.push({
        path: "/forgetthepassword",
      });
    },
    // 登录
    register() {
      if (this.form.verify == "") {
        this.$message({
          message: "请获取验证码",
          type: "cancel",
        });
        return false;
      }
      const loading = this.$loading({
        lock: true,
        text: "登录中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.getverificationCodeLogin_x({
        phone: this.form.phone,
        Verify: this.form.verify,
      }).then((res) => {
        loading.close();
        this.$message({
          message: res.message,
          type: res.successCode == 200 ? "success" : "cancel",
        });
        if (res.successCode == 200) {
          this.$store.commit("SET_LEARNING", 2);
          this.$store.commit("SET_forcetoexit", new Date().getTime());
          this.$store.commit("SET_video_shoot_time");
          this.getsmsVerificationr({
            student_id: res.responseBody.id,
            Verify: "88888",
            phone: res.responseBody.phone,
            username: res.responseBody.username,
            type: 1,
          });
          this.$router.push("/Layout/Learningcenter");
        }
      });
    },
    // 验证码倒计时
    getTime() {
      var tiemr = setInterval(() => {
        if (this.time >= 0) {
          this.time--;
        } else {
          clearInterval(tiemr);
        }
      }, 1000);
    },
    //获取验证码
    getCode() {
      if (!/^1[3456789]\d{9}$/.test(this.form.phone)) {
        this.$message({
          type: "cancel",
          message: "请输入正确的手机号",
        });
        return;
      }
      this.form.verify = "";
      this.getAlibabaMessage_x({
        phone: this.form.phone,
      }).then((res) => {
        this.$message({
          type: res.successCode == 200 ? "success" : "cancel",
          message: res.message,
        });
        if (res.successCode == 200) {
          this.time = 60;
          this.getTime();
        }
      });
    },
    // 微信登陆
    introduce() {
      this.$router.push("/wxlogin");
    },
    // 立即注册
    goregister() {
      this.$router.push("/register");
    },
  },
};
</script>

<style lang="scss" scoped>
input:focus {
  outline: none;
  border: 0 none;
}

.box {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
  position: relative;
  .img {
    width: 1922px;
    height: 531px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
  .mask {
    width: 1922px;
    height: 531px;
    background: rgba($color: #000000, $alpha: 0.3);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
  }
  .content {
    display: flex;
    width: 786px;
    height: 448px;
    background: #ffffff;
    box-shadow: 0px 5px 20px 0px #06316a;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1002;
    .content_img {
      width: 318px;
      height: 449px;
      border-radius: 10px 0px 0px 10px;
      vertical-align: middle;
    }
    .rigth {
      flex: 1;
      padding: 36px 64px;
      box-sizing: border-box;
      .logo {
        width: 143px;
        height: 48px;
      }
      .tab {
        margin-top: 42px;
        display: flex;
        justify-content: space-between;

        .item {
          cursor: pointer;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #444444;
        }
        .active {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1276fb;
        }
      }
      .phone {
        margin-top: 41px;
        padding-bottom: 5px;
        border-bottom: 1px solid #d4d9e2;
        input {
          &::placeholder {
            font-size: 16px;
          }
          margin-left: 13px;
          transform: translateY(-3px);
          font-size: 20px;
          border: 0 none;
          height: 30px;
        }
      }
      .password {
        margin-top: 30px;
        padding-bottom: 5px;
        border-bottom: 1px solid #d4d9e2;
        input {
          &::placeholder {
            font-size: 16px;
          }
          width: 100px;
          margin-left: 13px;
          transform: translateY(-3px);
          font-size: 20px;
          border: 0 none;
          height: 30px;
        }
        .verification {
          cursor: pointer;
          transform: translateY(-3px);
          float: right;
          text-align: center;
          line-height: 30px;
          width: 105px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          height: 30px;
          background: #1276fb;
          border-radius: 15px;
        }
      }
      .btn {
        cursor: pointer;
        margin-top: 37px;
        text-align: center;
        line-height: 47px;
        height: 47px;
        background: #1276fb;
        border-radius: 24px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .tac {
        margin-top: 8px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9c9c9c;
      }
    }
  }
}
</style>
